<template>
  <component is="b-card">
    <b-row>
      <b-col cols="12" md="4">
        <b-form-group :label="$t('Date')" label-for="range">
          <flat-pickr
            id="range"
            v-model="rangeDate"
            :config="{
              mode: 'range',
              dateFormat: 'Y-m-d',
            }"
            class="form-control"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <div>
      <b-card no-body class="mb-0">
        <b-table
          ref="refChargeListTable"
          class="position-relative"
          :items="fetchCharges"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          :empty-text="$t('No matching records found')"
          :sort-desc.sync="isSortDirDesc"
        >
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              class="btn-icon"
              @click="consulteCharge(data)"
            >
              <feather-icon icon="EyeIcon" size="16" />
            </b-button>

            <b-modal
              :ref="'consulte-charge-' + data.index"
              centered
              size="xl"
              @hidden="chargeDetails = []"
              :title="$t('Consultation')"
              hide-footer
              no-close-on-backdrop
            >
              <b-table
                show-empty
                :empty-text="$t('No matching records found')"
                :items="chargeDetails"
                :fields="fields"
                responsive
              >
                <template #cell(client)="data">
                  {{ data.item.order__client__name }}
                </template>
                <template #cell(t.qty)="data">
                  {{ data.item.t_qty }}
                </template>
                <template #cell(t.amount)="data">
                  {{ data.item.t_amount }}
                </template>
                <template #cell(d.arrive)="data">
                  {{ trackings[data.index].d_arrive }}
                </template>
                <template #cell(d.delivery)="data">
                  {{ trackings[data.index].d_delivery }}
                </template>
                <template #cell(at.client)="data">
                  {{ trackings[data.index].at_client }}
                </template>
              </b-table>
            </b-modal>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">{{
                $t("Pagination text", [dataMeta.from, dataMeta.to, dataMeta.of])
              }}</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalCharges"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>
  </component>
</template>

<script>
import {
  BTab,
  BTabs,
  BAlert,
  BLink,
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BPagination,
  BDropdown,
  BDropdownItem,
  BModal,
  VBModal,
  BCardText,
  BBadge,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import instance from "@/libs/axios";
import useChargeTracking from "./useChargeTracking";
import Ripple from "vue-ripple-directive";

export default {
  directives: {
    Ripple,
  },
  components: {
    vSelect,
    flatPickr,
    BTab,
    BTabs,
    BAlert,
    BLink,
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BPagination,
    BDropdown,
    BDropdownItem,
    BModal,
    VBModal,
    BCardText,
    BBadge,
  },

  data() {
    return {
      fields: [
        { key: "client", label: this.$t("client") },
        { key: "t.qty", label: this.$t("t.qty") },
        { key: "t.amount", label: this.$t("t.amount") },
        { key: "d.arrive", label: this.$t("d.arrive") },
        { key: "d.delivery", label: this.$t("d.delivery") },
        { key: "at.client", label: this.$t("at.client") },
      ],
      chargeDetails: [],
      trackings: [],
    };
  },
  methods: {
    async consulteCharge(charge) {
      try {
        const res = await instance.get(
          `/delivery/charge-tracking/${charge.item.id}`
        );

        this.chargeDetails = res.data.charge_details;
        this.trackings = res.data.trackings;

        this.$refs[`consulte-charge-${charge.index}`].show();
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: err.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
  },

  setup(props) {
    const {
      fetchCharges,
      tableColumns,
      perPage,
      currentPage,
      totalCharges,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refChargeListTable,
      refetchData,
      rangeDate,
    } = useChargeTracking();

    return {
      // Sidebar
      fetchCharges,
      tableColumns,
      perPage,
      currentPage,
      totalCharges,
      dataMeta,
      perPageOptions,

      sortBy,
      isSortDirDesc,
      refChargeListTable,
      refetchData,
      rangeDate,
    };
  },
};
</script>

<style></style>
