import { ref, watch, computed } from "@vue/composition-api";

// Notification
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import instance from "@/libs/axios";
import { useUtils as useI18nUtils } from "@core/libs/i18n";

export default function useChargeTracking() {
  // Use toast
  const { t } = useI18nUtils();

  const refChargeListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "ref", label: t("ref") },
    { key: "nclient", label: t("N.CLIENT") },
    { key: "nclient_delivered", label: t("N.CLIENT Livree") },
    { key: "tqty", label: t("T.QTY") },
    { key: "tamount", label: t("T.AMOUNT") },
    { key: "delivery_man.name", label: t("Delivery man") },
    { key: "departure_date", label: t("D.DEPART") },
    { key: "return_date", label: t("D.RETURN") },
    { key: "actions", label: t("Actions") },
  ];
  const perPage = ref(10);
  const totalCharges = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const rangeDate = ref("");

  const dataMeta = computed(() => {
    const localItemsCount = refChargeListTable.value
      ? refChargeListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCharges.value,
    };
  });

  const refetchData = () => {
    refChargeListTable.value.refresh();
  };

  watch([currentPage, perPage, rangeDate], () => {
    refetchData();
  });

  const fetchCharges = (ctx, callback) => {
    const dates = rangeDate.value ? rangeDate.value.split("to") : [];

    instance
      .get("/delivery/charge-tracking/", {
        params:
          dates.length == 2
            ? {
                page: currentPage.value,
                start_date: dates[0].trim(),
                end_date: dates[1].trim(),
              }
            : {
                page: currentPage.value,
              },
      })
      .then((response) => {
        const { count, results } = response.data;

        totalCharges.value = count;
        callback(results);
      })
      .catch((err) => {
      });
  };

  return {
    fetchCharges,
    tableColumns,
    perPage,
    currentPage,
    totalCharges,
    dataMeta,
    perPageOptions,

    sortBy,
    isSortDirDesc,
    refChargeListTable,
    refetchData,
    rangeDate,
  };
}
